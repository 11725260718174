<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="bannerData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('banner_fetch_error')}}
      </h4>
      <div class="alert-body">
        {{ $t('banner_not_found') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-banners-list'}"
        >
          Banner List
        </b-link>
        for other banners.
      </div>
    </b-alert>

    <template v-if="bannerData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <banner-view-banner-info-card :banner-data="bannerData" />
        </b-col>
        </b-row>
       
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import bannerStoreModule from '../bannerStoreModule'
import BannerViewBannerInfoCard from './BannerViewBannerInfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    BannerViewBannerInfoCard,
   

    InvoiceList,
  },
  setup() {
    const bannerData = ref(null)

    const BANNER_APP_STORE_MODULE_NAME = 'app-banner'
    if (!store.hasModule(BANNER_APP_STORE_MODULE_NAME)) store.registerModule(BANNER_APP_STORE_MODULE_NAME, bannerStoreModule)
    onUnmounted(() => {
      if (store.hasModule(BANNER_APP_STORE_MODULE_NAME)) store.unregisterModule(BANNER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-banner/fetchBanner', { id: router.currentRoute.params.id })
      .then(response => { 
        bannerData.value = response.data })
      .catch(error => {
          bannerData.value = undefined
      })

    return {
      bannerData,
    }
  },
}
</script>

<style>

</style>
