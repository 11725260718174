<template>
  <b-card>

    <b-row>

      <!-- Banner Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!--  & Action Buttons -->
        <div class="d-flex justify-content-start">
          
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ bannerData.name }}
              </h4>
            </div>
          </div>
        </div>

      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('labels.name') }}</span>
            </th>
            <td class="pb-50">
              {{ bannerData.name }}
            </td>
          </tr>
                  
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('labels.description') }}</span>
            </th>
            <td class="pb-50">
              {{ bannerData.description }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('labels.name') }} (URL)</span>
            </th>
            <td class="pb-50">
              {{ bannerData.urlImage }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('labels.type') }}</span>
            </th>
            <td class="pb-50">
              {{ bannerData.type }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('whitelabels') }}</span>
            </th>
          </tr>

           
          <tr
            v-for="item in bannerData.whitelabels"
            :key="item"
          >
            <th class="pb-50">
              
            </th>
            <td class="pb-50">
              {{ whitelabelName(item) }}
            </td>
          </tr>

        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import whitelabelStoreModule from '../../../../../../../../../pages/whitelabel/whitelabelStoreModule'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    bannerData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const WHITELABEL_APP_STORE_MODULE_NAME = 'app-whitelabel'
    if (!store.hasModule(WHITELABEL_APP_STORE_MODULE_NAME)) store.registerModule(WHITELABEL_APP_STORE_MODULE_NAME, whitelabelStoreModule)
    onUnmounted(() => {
      if (store.hasModule(WHITELABEL_APP_STORE_MODULE_NAME)) store.unregisterModule(WHITELABEL_APP_STORE_MODULE_NAME)
    })
    
    const whitelabels = ref([])
    store.dispatch('app-whitelabel/fetchWhitelabels')
      .then(response => { 
        whitelabels.value = response.data.items})

    return {
      whitelabels,
    }

  },
  methods: {
    whitelabelName(id) {
      const found = this.whitelabels.find(element => element._id == id)
      if (found) {
        return found.name
      }
     },  
  }

}
</script>

<style>

</style>
